<template>
  <div>
    <Drawer
      title="温馨提示"
      @on-close="closeDraw"
      placement="top"
      v-model="showDesc"
    >
      <div style="text-align: left">
        您的任务已提交，系统正在处理，
        <span v-if="taskInfo.taskIndex">
          需要等待
          <span v-if="taskInfo.taskIndex > 5">5</span>
          <span v-else>{{ taskInfo.taskIndex * 1 }}</span>
          分钟左右
        </span>
        请耐心等待！

        <!-- <div v-if="0 == taskInfo.taskIndex">任务进行中</div>
        <div v-if="0 != taskInfo.taskIndex" style="margin: 10px 0">
          任务已提交，当前队列排名:
          <span style="font-weight: bold; color: #866060">{{
            taskInfo.taskIndex
          }}</span>
        </div>

        <div style="margin: 10px 0">
          <span v-if="0 == taskInfo.taskIndex"> 当前任务执行时间:</span>
          <span v-else> 第一个任务执行时间:</span>

          <span style="font-weight: bold; color: #ed4014"
            >{{ taskTime }} 秒</span
          >
        </div>

        <div style="text-align: center" v-if="retryNum > 6">
          任务进行中，您可关闭或退出当前界面，稍后重试
        </div> -->
      </div>
    </Drawer>
    <loading-cmp v-if="showLoad"></loading-cmp>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
// @ is an alias to /src
import { statsPost } from "../api/httpApi";
import loadingCmp from "../components/loadingCmp.vue";

export default {
  name: "taskLoadCom",
  components: { loadingCmp },
  data() {
    return {
      showDesc: false,
      retryNum: 0,
      taskInfo: {},
      showLoad: false, // 数据加载
      showLoadNum: 0, // 只显示一次任务进行中
      taskTime: 0,
      timmer: null,
    };
  },
  methods: {
    cleatTimmer() {
      if (this.timmer) {
        clearTimeout(this.timmer);
      }
    },
    async initData() {
      // console.log("-----------", this.retryNum);
      try {
        this.showLoad = true;
        console.log("initData task--------", this.postUrl, this.postData);
        const ret = await statsPost(this.postUrl, this.postData);

        // status  "等待中"
        // taskIndex

        // 当前时间：HH:mm:ss
        // 任务状态：进行中/等待中
        // 任务队列长度：N （前面还有N个任务）
        // 第一个任务已执行：x秒（startTime_running==0时为0秒，其他为newDate格式，前端计算时间差）

        if (ret.status) {
          this.taskInfo = ret;
          this.showDesc = true;

          this.taskTime = this.taskInfo.runSeconds;
          // console.log("------", this.taskInfo.runSeconds);

          this.timmer = setTimeout(async () => {
            this.retryNum++;
            await this.initData();
          }, 2000);

          return;
        }
        this.clearStatus();

        this.$emit("initData", ret);
      } catch (error) {
        console.log("error end------", error);
        this.$Message.info("系统繁忙，请稍后重试");
        this.clearStatus();
        this.$emit("initData");
      }
    },
    closeDraw() {
      this.clearStatus();
      this.$emit("initData");
    },
    clearStatus() {
      this.showLoad = false;
      this.showDesc = false;
      this.showLoadNum = 0;
    },
  },
  props: {
    // 内容数据
    postData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    postUrl: {
      type: String,
      default: "",
    },

    showTaskLoad: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showTaskLoad: function (val) {
      if (this.showTaskLoad) {
        // this.initData();
      }
    },
  },
  async created() {
    this.initData();
  },
  destroyed() {
    this.cleatTimmer();
  },
};
</script>

<style scoped>
</style>
